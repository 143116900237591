import React, { Fragment } from "react";
import { Card, CardHeader, CardBody, CardText } from "reactstrap";
import { TermsConditionData } from "../common/data/terms-conditions";
import Container from "../common/components/UI/Container";
import Logo from "../common/components/UIElements/Logo";
import LogoImage from "../common/assets/image/logo/bitexbot-logo-text.png";
import Seo from "components/seo";

const TermsConditions = () => {
  return (
    <Fragment>
      <Seo title="Terms and Conditions" description="Bitexbot Terms of Use, Policies and Disclaimers" />
      <section className="w-100 text-center my-3">
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Bitexbot"
          className="main-logo"
        />
      </section>
      <section>
        <Container className="px-5">
          <Card>
            <CardHeader>Last updated on: September 02, 2021</CardHeader>
            <CardBody>
              <CardText tag="h2" className="mb-2">
                Terms of Use, Policies and Disclaimers
              </CardText>
              <CardText tag="h5" className="mb-2">
                Your affirmative act of using our website located at
                www.bitexbot.org (and all associated sites linked to it) or
                services ("Bitexbot") signifies that you agree to the following
                terms and conditions of use ("Terms of Use"). If you do not
                agree, do not use Bitexbot ("us", "we" or "our").
              </CardText>
              {TermsConditionData.map((terms, index) => {
                return (
                  <Fragment>
                    <CardText tag="h4">
                      {index + 1}. {terms.title}
                    </CardText>
                    <CardText
                      dangerouslySetInnerHTML={{ __html: terms.description }}
                    />
                  </Fragment>
                );
              })}
            </CardBody>
          </Card>
        </Container>
      </section>
    </Fragment>
  );
};
export default TermsConditions;
