export const TermsConditionData = [
  {
    title: 'Changes to the terms of use',
    description:
      'We may change these Terms of Use at any time. If you continue to use Bitexbot after we post changes to these Terms of Use, you are signifying your acceptance of the new terms. You will always have access to our Terms of Use and will be able to check it at any time. By reloading this page, you will have the most up-to-date version available to Bitexbot users.',
  },
  {
    title: 'Changes to Bitexbot',
    description: `
          We may discontinue or change any service or feature on Bitexbot at any time without notice. 
          We do not guarantee backward compatibility of our services and Application Programming Interface (API) in this case.
          `,
  },
  {
    title:
      'Ownership of information; License to use Bitexbot; redistribution of data',
    description: `Unless otherwise noted, all rights, titles, and interests in Bitexbot, and all information made available 
      through Bitexbot or our services, in all languages, formats, and media throughout the world, including, but not limited to, 
      all copyrights and trademarks therein, are the exclusive property of Bitexbot, our affiliates or our Data Providers, 
      as defined in section 6 (disclaimer regarding content) below.<br /><br />
      You may not use any data mining, robots or similar data gathering and extraction tools on the Bitexbot content, 
      frame any portion of Bitexbot or its content, sublicense, assign, transfer, sell, loan, or otherwise distribute for payment 
      the Bitexbot content without our prior written consent. You may not circumvent any mechanisms included in the Bitexbot content 
      for preventing the unauthorized reproduction or distribution of the Bitexbot content. For additional information on commercial 
      and partnership inquiries with us, please contac us at <a href="mailto:support@bitexbot.org">support@bitexbot.org</a>.<br /><br />
      <strong>Except as otherwise expressly permitted by the preceding paragraph, you agree not to sell any of the services or materials in any manner 
      or for any purposes, without the prior expressed written consent of Bitexbot and/or our Data Providers. In addition, you shall not, 
      without the prior expressed written consent of Bitexbot and the relevant Data Providers, make copies of any of the software or 
      documentation that may be provided, electronically or otherwise, including, but not limited to, translating, decompiling, disassembling 
      or creating derivative works.</strong><br /><br />
      Except as otherwise expressly permitted by additional agreement, we do not permit commercial usage of 
      any of our services or APIs. We make no warranty and assume no obligation or liability for third party services or software. 
      Please see section 19 (third party software) below for further information.
      `,
  },
  {
    title: 'Attribution',
    description: `
          Bitexbot grants all users of bitexbot.org, and all other available versions of the site, to use snapshots of Bitexbot dashoard, press releases, books, 
          articles, blog posts and other publications. In addition, Bitexbot grants the use of all previously mentioned materials in education sessions, 
          the display of Bitexbot website during video broadcasts, which includes overviews, news, analytics and otherwise use or promote Bitexbot services
          or any products from the Bitexbot website on the condition that Bitexbot attribution is clearly visible at all times when such services and products are used.
          <br /><br />
          <strong>Attribution must include a reference to Bitexbot, including, but not limited to, those described herein.</strong>
          <br /><br />
          Use of Bitexbot website during video or other promotions where Bitexbot attribution is not visible, must include a description of the product used therein.
          For instance: Font size should not be less than 10 PT = 13PX = 3.4MM. 
          <br /><br />
          The use of any Bitexbot products outside the Bitexbot website, without a proper attribution of Bitexbot, is not allowed. Users who disobey this 
          attribution rule herein may be banned permanently, and other legal actions may be taken to ensure compliance. This includes, but is not limited to, 
          cease-and-desist warnings, court orders, injunctions, fines, damages relief, and so on.
        `,
  },
  {
    title: 'Third party sites and advertisers',
    description: `
      Bitexbot may include links to third party websites. Some of these sites may contain materials that are objectionable, unlawful, or inaccurate. 
      You agree that Bitexbot shall not be held liable for any trading activities or other activities that occur on any website you access through links on Bitexbot. 
      We provide these links as a convenience, and do not endorse the content or services offered by these other sites. Any dealings that you have with advertisers 
      found on Bitexbot are between you and the advertiser and you acknowledge and agree that we are not liable for any loss or claim you may have against an advertiser.
      <br /><br />
      Unauthorized soliciting on Bitexbot is strictly prohibited and may result in penalties, including, but not limited to, temporary or permanent bans of the account 
      found in violation, and any appropriate and available legal action for monetary and other damages.
      `,
  },
  {
    title: 'Disclaimer regarding content',
    description: `
      Bitexbot cannot and does not represent or guarantee that any of the information available through our services or on Bitexbot is accurate, 
      reliable, current, complete or appropriate for your needs. Various information available through our services or on Bitexbot may be specially 
      obtained by Bitexbot from professional businesses or organizations, such as exchanges, news providers, market data providers and other content 
      providers (e.g. Cboe BZX and possibly other established financial exchanges such as the New York Stock Exchange, NASDAQ, 
      New York Mercantile Exchange and Dow Jones), who are believed to be sources of reliable information (collectively, the "Data Providers"). 
      Nevertheless, due to various factors — including the inherent possibility of human and mechanical error — the accuracy, completeness, timeliness, 
      results obtained from use, and correct sequencing of information available through our services and website are not and cannot be guaranteed by Bitexbot. 
      We make no warranty and assume no obligation or liability for any content of third parties. Your use of any third-party content is at your sole risk.
      `,
  },
  {
    title: 'Disclaimer regarding investment decisions and trading',
    description: `
      Decisions to buy, sell, hold or trade in securities, commodities and other investments involve risk and are best made based on the advice of qualified 
      financial professionals. Any trading in securities or other investments involves a risk of substantial losses. The practice of "Day Trading" involves 
      particularly high risks and can cause you to lose substantial sums of money. Before undertaking any trading program, you should consult a qualified 
      financial professional. Please consider carefully whether such trading is suitable for you in light of your financial condition and ability to bear 
      financial risks. Under no circumstances shall we be liable for any loss or damage you or anyone else incurs as a result of any trading or investment 
      activity that you or anyone else engages in based on any information or material you receive through Bitexbot or our services.
      `,
  },
  {
    title: 'Registered users',
    description: `
      Certain services, are available only to registered users of the Bitexbot website and require you to sign in with a username and password to use them. 
      If you register as a user (a "Subscriber") of any of the features of Bitexbot, during the registration process you may be prompted to click 
      "Register Now", "Submit" or a similar button; your clicking on such button will further confirm your agreement to be legally bound by these Terms of Use.
      <br /><br />
      In consideration of your use of the Bitexbot website, you represent that you are of legal age to form a binding contract and are not a person barred from 
      receiving Bitexbot services under the laws of the United States or other applicable jurisdiction. You also agree to: (a) provide true, accurate, current 
      and complete information about yourself as prompted by the Bitexbot's registration form (the "Registration Data") and (b) maintain and promptly update 
      the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, 
      or Bitexbot has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, Bitexbot has the right to suspend or 
      terminate your account and refuse any and all current or future use of the Bitexbot services (or any portion thereof).
      `,
  },
  {
    title: 'Access and security',
    description: `
      You accept responsibility for the confidentiality and use of any username and email address that use to register for your access to and use of our services. 
      You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under your password or account. 
      You agree to (a) immediately notify Bitexbot of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you 
      exit from your account at the end of each session. Bitexbot cannot and will not be liable for any loss or damage arising from your failure to comply.
      <br /><br />
      Bitexbot considers private information on the site to be confidential to you. Bitexbot protects such private information from unauthorized use, 
      access, or disclosure in the same manner that it protects personal data (please refer to our <a href="/privacy-policy">Privacy Policy</a> for more information).
      `,
  },
  {
    title: 'Payment and cancellation of service',
    description: `
      <ol>            
          <li>By ordering any subscription on www.bitexbot.org you confirm that you have read and accepted our Terms of Use.</li>
          <li>The service is billed in advance on a monthly, 3 months, 6 months or annual basis. There will be no refunds or credits for partial months of service, or refunds for months unused with an open user account.</li>
          <li>If you cancel the service before the end of your current paid up period, your subscription will remain active until the next due date. After the due date, if no payment received, your subscription will be stopped.</li>
          <li>We do not offer refunds for any subscription payments. If you order the service, please make sure that the order is correct before the payment is made.</li>
          <li>We do not offer refunds for upgrades to a more expensive plan or a longer billing cycle. Remaining days are converted into an equivalent value of days on the new subscription.</li>
          <li>Our moderators are official Bitexbot representatives, with the authority to warn or ban users (including those with a paid subscription) when they do not abide by this Terms of Use. We do not offer refunds in case you are banned because your paid subscription and all of its data and/or features are still available during any such ban.</li>
      </ol>
      `,
  },
  {
    title: 'Indemnity',
    description: `
      You agree to indemnify and hold Bitexbot and our subsidiaries, affiliates, officers, agents, employees, partners and licensors harmless 
      from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of content you submit, 
      post, transmit, modify or otherwise make available through the Bitexbot services, your use of the Bitexbot services, your connection 
      to the Bitexbot services, your violation of the Terms of Use, or your violation of any rights of another.
      `,
  },
  {
    title: 'Termination',
    description: `
          Upon your request, your account can be deleted from Bitexbot. You may submit a request to delete your account at <a href="mailto:support@bitexbot.org">support@bitexbot.org</a>. 
          If you requested your account be deleted, we will delete the personal data associated with your account, however certain personal data will be retained if it has 
          been integrated into data that is integral to our systems and site, including data in communications you have sent via our site. This data is necessarily retained 
          for the integrity of the data on the site and our legitimate business purposes, including auditing, security and other legitimate interests (please refer to our 
          <a href="/privacy-policy">Privacy Policy</a> for more information).
          <br /><br />
          You agree that Bitexbot may, without prior notice, immediately terminate, limit your access to or suspend your Bitexbot 
          account, any associated email address, and access to the Bitexbot services. Cause for such termination, limitation of access or suspension shall include, 
          but not be limited to, (a) breaches or violations of the Terms of Use or other incorporated agreements or guidelines, (b) requests by law enforcement or other 
          government agencies, (c) discontinuance or material modification to the Bitexbot services (or any part thereof), (d) unexpected technical or security issues 
          or problems, (e) extended periods of inactivity, (f) and/or engagement by you in fraudulent or illegal activities. Further, you agree that all terminations, 
          limitations of access and suspensions for cause shall be made in Bitexbot's sole discretion and that Bitexbot shall not be liable to you or any third party 
          for any termination of your account, any associated email address, or access to the Bitexbot services.
      `,
  },
  {
    title: 'Emails',
    description: `
          By creating an account with Bitexbot, you agree that Bitexbot can use your email address to send you marketing materials, service-related notices, 
          important information messages, special offers, etc. You can unsubscribe from this by clicking on the link provided in the emails.
      `,
  },
  {
    title: 'Third Party Software',
    description: `
          Our software, website and/or services may include software components supplied by third parties which are utilized by permission of the respective 
          licensors and/or copyright holders on the terms provided by such parties ("Third Party Software"). Bitexbot expressly disclaims any warranty or 
          other assurance to you regarding Third Party Software. Please note that your use of the Third Party Software will be governed by the terms and 
          conditions of use and privacy policies of the Third Party Software providers and not by these Terms of Use or our <a href="/privacy-policy">Privacy Policy</a>.
      `,
  },
];
